.eua-emission-board-total-table-wrapper {
    width: 100%;
    height: 60px;
}

.eua-emission-board-total-table {
    
    width: 100%;
    height: 100%;
    margin: 0 auto;
    /* border-collapse: collapse; */
    table-layout: fixed;
    display: block;

    thead tr {
        font-size: 13px;
        width: 100%;
        /* height: 100%; */
        /* align-items: center; */
        display: table;
        
    }
    tbody tr {
        font-size: 14px;
        width: 100%;
        height: 30px;
        /* align-items: center; */
        display: table;
        margin-top: -5px;
    }

    thead {
        display: table;
        width: 100%;

        th {
            /* border: 1px #CBCBCB solid; */
            font-family: Roboto, "Helvetica Neue", sans-serif;
            font-weight: 700;
            position: sticky;

            span {
                font-size: 14px;
                color: rgb(255, 255, 255, 0.5);
            }
        }

        .padding1 {
            width: 2%;
            text-align: left;
        }

        .total-foc {
            width: 9%;
            text-align: left;
        }

        .total-ghg {
            width: 10%;
            text-align: left;
        }

        .total-distance {
            width: 12%;
            text-align: left;
        }

        .total-eua {
            width: 8%;
            text-align: left;
        }

        .total-eua-cost {
            width: 11%;
            text-align: left;
        }

        .total-compliance-balance {
            width: 17%;
            text-align: left;
        }

        .total-compliance-balance-cost {
            width: 24%;
            text-align: left;
        }

        .padding2 {
            width: 7%;
            text-align: left;
        }

    }

    tbody {
        display: block;
        overflow-y: auto;
        /* height: 90%; */
        width: 100%;

        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.5);
        }

        &::-webkit-scrollbar-thumb {
            background-color: #fff;
            border-radius: 10px;
        }

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-corner {
            display: none;
        }

        tr {

            width: 100%;

            td {
                padding-bottom: 0px;
                /* border: 1px #CBCBCB solid; */
                font-family: Roboto;
                font-weight: 400;
                font-size: 14px;
                word-wrap: 'break-word';
                vertical-align: middle;
                white-space: nowrap;
                /* height: 30px; */
            }

            .padding1 {
                width: 2.2%;
                text-align: left;
            }
    
            .total-foc {
                width: 9%;
                text-align: left;
            }
    
            .total-ghg {
                width: 10%;
                text-align: left;
            }
    
            .total-distance {
                width: 12%;
                text-align: left;
            }
    
            .total-eua {
                width: 8%;
                text-align: left;
            }
    
            .total-eua-cost {
                width: 11%;
                text-align: left;
            }
    
            .total-compliance-balance {
                width: 17%;
                text-align: left;
            }
    
            .total-compliance-balance-cost {
                width: 24%;
                text-align: left;
            }
    
            .padding2 {
                width: 6.8%;
                text-align: left;
            }
        }
    }
}

.eu-emission-board-total-table-data {
    font-size: 15px;
    color: rgb(255, 255, 255, 1);
}

.eu-emission-board-total-table-unit {
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    color: rgb(255, 255, 255, 0.5);
}

.eua-emission-board-list-wrapper {
    width: 98%;
    height: calc(100% - 125px);
    margin-left: 12px;
}

.eua-emission-board-list-leg {
    
    width: 100%;
    height: 100%;
    margin: 0 auto;
    /* border-collapse: collapse; */
    table-layout: fixed;
    display: block;

    thead tr {
        font-size: 13px;
        width: 100%;
        /* height: 100%; */
        /* align-items: center; */
        display: table;
        border-bottom: 1px dotted rgb(255, 255, 255, 0.5);
    }
    tbody tr {
        font-size: 14px;
        width: 100%;
        display: table;
    }

    thead {
        display: table;
        width: 100%;

        th {
            /* border: 1px #CBCBCB solid; */
            font-family: Roboto, "Helvetica Neue", sans-serif;
            font-weight: 700;
            position: sticky;

            span {
                font-size: 14px;
                color: rgb(255, 255, 255, 0.5);
            }
        }

        .list-padding {
            width: 1%;
            text-align: center;
        } 

        .list-leg-no {
            width: 3.5%;
            text-align: left;
        }

        .list-state {
            width: 4.5%;
            text-align: center;
        }

        .list-departure {
            width: 13%;
            text-align: left;
        }

        .list-padding1 {
            width: 3%;
            text-align: left;
        }

        .list-arrival {
            width: 13%;
            text-align: left;
        }

        .list-time-total {
            width: 7%;
            text-align: left;
        }

        .list-eu-rate {
            width: 7%;
            text-align: left;
        }

        .list-displacement {
            width: 11%;
            text-align: left;
        }

        .list-distance {
            width: 8%;
            text-align: left;
        }

        .list-fuel {
            width: 9%;
            text-align: left;
        }

        .list-foc {
            width: 6%;
            text-align: left;
        }

        .list-eua {
            width: 6%;
            text-align: left;
        }

        .list-cb {
            width: 6%;
            text-align: left;
        }

        .list-padding2 {
            width: 2%;
            text-align: left;
        }

    }

    tbody {
        display: block;
        overflow-y: auto;
        height: 85%;
        width: 100%;

        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.5);
        }

        &::-webkit-scrollbar-thumb {
            background-color: #fff;
            border-radius: 10px;
        }

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        &::-webkit-scrollbar-corner {
            display: none;
        }

        tr {

            width: 100%;

            &:nth-of-type(odd) {
                background-color: rgba(255, 255, 255, 0.05);
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.15);
            }

            td {
                padding-bottom: 0px;
                /* border: 1px #CBCBCB solid; */
                font-family: Roboto;
                font-weight: 400;
                word-wrap: 'break-word';
                vertical-align: middle;
                white-space: nowrap;
                height: 55px;
                max-height: 55px;

                span {
                    font-size: 15px;
                    color: rgb(255, 255, 255, 1);
                }
            }

            .list-padding {
                width: 1%;
                text-align: center;
            } 
    
            .list-leg-no {
                width: 3.5%;
                max-width: 50px;
                text-align: left;
                padding-left: 10px;
            }
    
            .list-state {
                width: 4.5%;
                max-width: 100px;
                text-align: center;
            }
    
            .list-departure {
                width: 13%;
                max-width: 100px;
                text-align: left;
            }
    
            .list-padding1 {
                width: 3%;
                text-align: left;
            }
    
            .list-arrival {
                width: 13%;
                max-width: 100px;
                text-align: left;
            }
    
            .list-time-total {
                width: 7%;
                max-width: 50px;
                text-align: right;
                padding-right: 25px;
            }
    
            .list-eu-rate {
                width: 7%;
                max-width: 50px;
                text-align: right;
                padding-right: 22px;
            }
    
            .list-displacement {
                width: 11%;
                max-width: 120px;
                text-align: right;
                padding-right: 30px;
            }
    
            .list-distance {
                width: 8%;
                max-width: 100px;
                text-align: right;
                padding-right: 30px;
            }
    
            .list-fuel {
                width: 9%;
                max-width: 60px;
                text-align: left;
            }
    
            .list-foc {
                width: 6%;
                max-width: 50px;
                text-align: left;
            }
    
            .list-eua {
                width: 6%;
                max-width: 70px;
                text-align: right;
                padding-right: 20px;
            }
    
            .list-cb {
                width: 6%;
                max-width: 50px;
                text-align: right;
                padding-right: 5px;
            }
    
            .list-padding2 {
                width: 2%;
                text-align: left;
            }   
        }
    }
}

.eua-emission-board-list-voyage {
    
    width: 100%;
    height: 100%;
    margin: 0 auto;
    /* border-collapse: collapse; */
    table-layout: fixed;
    display: block;

    thead tr {
        font-size: 13px;
        width: 100%;
        /* height: 100%; */
        /* align-items: center; */
        display: table;
        border-bottom: 1px dotted rgb(255, 255, 255, 0.5);
    }
    tbody tr {
        font-size: 14px;
        width: 100%;
        display: table;
    }

    thead {
        display: table;
        width: 100%;

        th {
            /* border: 1px #CBCBCB solid; */
            font-family: Roboto, "Helvetica Neue", sans-serif;
            font-weight: 700;
            position: sticky;

            span {
                font-size: 14px;
                color: rgb(255, 255, 255, 0.5);
            }
        }

        .list-padding {
            width: 1%;
            text-align: center;
        } 

        .list-voyage-no {
            width: 6%;
            text-align: left;
        } 

        .list-operator {
            width: 7%;
            text-align: left;
        }

        .list-departure {
            width: 14%;
            text-align: left;
            /* padding-left: 10px; */
        }

        .list-padding1 {
            width: 3%;
            text-align: left;
        }

        .list-arrival {
            width: 14%;
            text-align: left;
        }

        .list-time-total {
            width: 7%;
            text-align: left;
        }

        .list-displacement {
            width: 12%;
            text-align: left;
        }

        .list-distance {
            width: 8%;
            text-align: left;
        }

        .list-fuel {
            width: 9%;
            text-align: left;
        }

        .list-foc {
            width: 6%;
            text-align: left;
        }

        .list-eua {
            width: 6%;
            text-align: left;
        }

        .list-cb {
            width: 6%;
            text-align: left;
        }

        .list-padding2 {
            width: 2%;
            text-align: left;
        }

    }

    tbody {
        display: block;
        overflow-y: auto;
        height: 85%;
        width: 100%;

        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.5);
        }

        &::-webkit-scrollbar-thumb {
            background-color: #fff;
            border-radius: 10px;
        }

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        &::-webkit-scrollbar-corner {
            display: none;
        }

        tr {

            width: 100%;

            &:nth-of-type(odd) {
                background-color: rgba(255, 255, 255, 0.05);
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.15);
            }

            td {
                padding-bottom: 0px;
                /* border: 1px #CBCBCB solid; */
                font-family: Roboto;
                font-weight: 400;
                word-wrap: 'break-word';
                vertical-align: middle;
                white-space: nowrap;
                height: 55px;
                max-height: 55px;
                span {
                    font-size: 15px;
                    color: rgb(255, 255, 255, 1);
                }
            }

            .list-padding {
                width: 1%;
                text-align: center;
            } 
    
            .list-voyage-no {
                width: 6%;
                max-width: 70px;
                text-align: left;
                padding-left: 10px;
            } 
    
            .list-operator {
                width: 7%;
                max-width: 80px;
                text-align: left;
            }
    
            .list-departure {
                width: 14%;
                max-width: 140px;
                text-align: left;
                /* padding-left: 10px; */
            }
    
            .list-padding1 {
                width: 3%;
                text-align: left;
            }
    
            .list-arrival {
                width: 14%;
                max-width: 140px;
                text-align: left;
            }
    
            .list-time-total {
                width: 7%;
                max-width: 70px;
                text-align: right;
                padding-right: 25px;
            }
    
            .list-displacement {
                width: 12%;
                max-width: 120px;
                text-align: right;
                padding-right: 40px;
            }
    
            .list-distance {
                width: 8%;
                max-width: 90px;
                text-align: right;
                padding-right: 30px;
            }
    
            .list-fuel {
                width: 9%;
                max-width: 90px;
                text-align: left;
            }
    
            .list-foc {
                width: 6%;
                max-width: 70px;
                text-align: left;
            }
    
            .list-eua {
                width: 6%;
                max-width: 70px;
                text-align: right;
                padding-right: 15px;
            }
    
            .list-cb {
                width: 6%;
                max-width: 60px;
                text-align: right;
                padding-right: 10px;
            }
    
            .list-padding2 {
                width: 2%;
                text-align: left;
            }
        }
    }
}

.average-titole {
    font-size: 14px;
    font-weight: bold;
    font-family: Roboto;
    color: rgba(255, 255, 255, 0.5);
    padding-right: 5px;
}
.average-value {
    font-size: 15px;
    font-family: Roboto;
    color: white;
    padding-right: 5px;
}
.average-unit {
    font-size: 15px;
    font-family: Roboto Condensed;
    color: rgba(255, 255, 255, 0.5);   
    padding-right: 20px;
}

.eu-emission-board-pointer-area{
    cursor:pointer
}

.eu-emission-board-pointer-area:hover{
    background-color: #7a86a0
}
