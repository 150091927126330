.cii-total-area {
    height: 197px;
    width: 24%;
    font-family: 'Roboto';
    background: linear-gradient(
        180deg,
        rgba(46, 46, 48, 0.30) 0%, 
        rgba(65, 66, 68, 0.30) 20%
    );
    .cii-total-area-titole-1 {
        width: 133px;
        font-size: 15px;
        font-weight: bold;
        color: white;
        padding-left: 24px;
        display: flex;
        align-items: flex-end;
    }
    .cii-total-area-titole-2 {
        width: 127px;
        font-size: 15px;
        font-weight: bold;
        color: white;
        padding-left: 24px;
        display: flex;
        align-items: flex-end;
    }
    .cii-total-area-titole-3 {
        width: 213px;
        font-size: 15px;
        font-weight: bold;
        color: white;
        padding-left: 24px;
        display: flex;
        align-items: flex-end;
    }
    .cii-total-area-titole-4 {
        width: 105px;
        font-size: 15px;
        font-weight: bold;
        color: white;
        padding-left: 24px;
        display: flex;
        align-items: flex-end;
    }
    .cii-total-area-total-vessels {
        width: 95px;
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: flex-end;
    }
    .cii-total-area-total-vessels-value {
        width: 95px;
        font-size: 14px;
        color: white;
        display: flex;
        align-items: flex-end;
        padding-left: 3px;
    }
    .cii-total-area-total-graph-legend-wrapper {
        height: 161px;
        width: 56%; 

        .cii-total-area-total-graph-legend-margin {
            height: 15px;
            width: 100%; 
        }
        .cii-total-area-total-graph-legend {
            height: 25px;
            width: 100%;

            .legend {
                width: 8%;
                height: 100%;

                .legend-A {
                    height: 6px;
                    width: 6px;
                    background-color: rgba(0, 144, 206, 0.4);
                    margin:0 auto;
                    margin-top: 8px;
                }
                .legend-B {
                    height: 6px;
                    width: 6px;
                    background-color: rgba(6, 165, 80, 0.4);
                    margin:0 auto;
                    margin-top: 8px;
                }
                .legend-C {
                    height: 6px;
                    width: 6px;
                    background-color: rgba(212, 195, 38, 0.4);
                    margin:0 auto;
                    margin-top: 8px;
                }
                .legend-D {
                    height: 6px;
                    width: 6px;
                    background-color: rgba(255, 103, 103, 0.45);
                    margin:0 auto;
                    margin-top: 8px;
                }
                .legend-E {
                    height: 6px;
                    width: 6px;
                    background-color: rgba(255, 47, 47, 0.45);
                    margin:0 auto;
                    margin-top: 8px;
                }
            }

            .legend-name {
                width: 7%;
                height: 100%;
                font-size: 14px;
                font-weight: bold;
                color: rgba(255, 255, 255, 0.5);
                padding-left: 2px;
                margin-top: 0px;
            }

            .legend-colon {
                width: 5%;
                height: 100%;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.5);
                padding-left: 2px;
            }

            .legend-percent-value {
                width: 10%;
                height: 100%;
                font-size: 17px;
                color: white;
                text-align: end;
                margin-top: -3px;
            }

            .legend-percent-unit {
                width: 7%;
                height: 100%;
                font-size: 15px;
                color: rgba(255, 255, 255, 0.5);
                padding-left: 5px;
                font-family: "Roboto COndensed";
                margin-top: 1px;
            }
            
            /* 横線 */
            .horizontal-bar {
                height: 100%;
                width: 15%;
        
                .bar {
                    margin-top: 10px;
                    margin-left: 7px;
                    height: 1px;
                    width: 60%;
                    background: linear-gradient(
                    to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0));
                }
            }

            .legend-vessel-count-value {
                width: 10%;
                height: 100%;
                font-size: 17px;
                color: white;
                text-align: end;
                margin-top: -3px;
            }

            .legend-vessel-count {
                width: 7%;
                height: 100%;
                font-size: 15px;
                color: rgba(255, 255, 255, 0.5);
                padding-left: 3px;
                font-family: "Roboto COndensed";
            }

        }
    }
}

.cii-total-area-margin {
    height: 197px;
    width: 2%;
}

.cii-list-area {
    height: calc(100% - 295px);
    background: linear-gradient(
        180deg,
        rgba(46, 46, 48, 0.30) 0%, 
        rgba(65, 66, 68, 0.30) 20%
    );
}

.cii-list-area-wrapper {
    width: 97%;
    height: calc(100% - 85px);
    margin: 0 auto;
    white-space: nowrap;
    overflow-y: hidden;

    .cii-vessel-list-table {
        font-size: 14px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        /* border-collapse: collapse; */
        table-layout: fixed;
        display: block;

        thead tr {
            width: 100%;
            /* height: 100%; */
            align-items: center;
            display: table;
            border-bottom: 1px dotted rgb(255, 255, 255, 0.5);
        }
        tbody tr {
            width: 100%;
            height: 50px;
            align-items: center;
            display: table;
        }

        thead {
            display: table;
            width: 100%;

            th {
                /* border: 1px #CBCBCB solid; */
                font-family: Roboto, "Helvetica Neue", sans-serif;
                font-weight: 700;
                position: sticky;

                span {
                    font-size: 14px;
                    color: rgb(255, 255, 255, 0.5);
                }
            }
            .padding1 {
                width: 1.2%;
            }
            .vesselName {
                width: 10.7%;
            }

            .l4w-distance {
                width: 7%;
            }

            .l4w-foc {
                width: 6.7%;
            }

            .l4w-cii-score {
                width: 6.4%;
            }
            .ytd-distance {
                width: 9.3%;
            }

            .ytd-foc {
                width: 6.7%;
            }

            .ytd-cii-score {
                width: 6.4%;
            }

            .eoy-distance {
                width: 9.3%;
            }

            .eoy-foc {
                width: 6.7%;
            }

            .eoy-cii-score {
                width: 6.4%;
            }

            .ly-distance {
                width: 9.3%;
            }

            .ly-foc {
                width: 6.7%;
            }

            .ly-cii-score {
                width: 7.2%;
                padding-right: 0.8%;
            }
        }

        tbody {
            display: block;
            overflow-y: auto;
            height: 90%;
            width: 100%;

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background: rgba(255, 255, 255, 0.5);
            }

            &::-webkit-scrollbar-thumb {
                background-color: #fff;
                border-radius: 10px;
            }

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-corner {
                display: none;
            }

            tr {

                width: 100%;

                &:nth-of-type(odd) {
                    background-color: rgba(255, 255, 255, 0.05);
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.15);
                }

                td {
                    padding-bottom: 0px;
                    /* border: 1px #CBCBCB solid; */
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 15px;
                    word-wrap: 'break-word';
                    vertical-align: middle;
                    white-space: nowrap;
                    height: 50px;
                    max-height: 50px;

                    span {
                        font-size: 15px;
                        color: rgb(255, 255, 255, 1);
                    }
                }

                .padding1 {
                    width: 1.2%;
                }

                .vesselName {
                    width: 10.7%;
                    max-width: 100px;
                    text-align: left;
                }
    
                .l4w-distance {
                    width: 7%;
                    max-width: 100px;
                    text-align: right;
                }
    
                .l4w-foc {
                    width: 6.7%;
                    max-width: 100px;
                    text-align: right;
                }
    
                .l4w-cii-score {
                    width: 6.4%;
                    text-align: right;
                }
                .ytd-distance {
                    width: 9.3%;
                    max-width: 100px;
                    text-align: right;
                }
    
                .ytd-foc {
                    width: 6.7%;
                    max-width: 100px;
                    text-align: right;
                }
    
                .ytd-cii-score {
                    width: 6.4%;
                    text-align: right;
                }
    
                .eoy-distance {
                    width: 9.3%;
                    max-width: 100px;
                    text-align: right;
                }
    
                .eoy-foc {
                    width: 6.7%;
                    max-width: 100px;
                    text-align: right;
                }
    
                .eoy-cii-score {
                    width: 6.4%;
                    text-align: right;
                }
    
                .ly-distance {
                    width: 9.3%;
                    max-width: 100px;
                    text-align: right;
                }
    
                .ly-foc {
                    width: 6.7%;
                    max-width: 100px;
                    text-align: right;
                }
    
                .ly-cii-score {
                    width: 7.2%;
                    text-align: right;  
                    padding-right: 0.8%;
                }
            }
        }
    }
}

/* テキストを右揃え */
.cii-list-text-right {
    width: 100%;
    text-align: right;
}

