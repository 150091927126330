.searchVesselName {
    width: 265px;
    /* max-width: 276px;
    min-width: 150px; */
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #616161;
    font-size: 15px;
    font-family:    
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"';
    cursor: pointer;
    color: white;
    background: rgba(255, 255, 255, 0.15);
    border: 1px #CBCBCB solid;

    border-Radius: 30px;

    &:hover {
        background-color: #7a86a0;
    }

    span {
        display: inline-block;
        padding-left: 20px;
        max-width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
.search-vessel-name-dialog {
    opacity: 2;

    .panel-title {
        padding: 0;
        height: 81px;
        background: #313741;

        .search-vessel-container {
            .searchInput {
                width: 90%;
                height: 35px;
                box-sizing: border-box;
                background-color: rgba(255, 255, 255, 0.25);
                /* border-radius: 30px; */
                border: 2px solid rgba(255, 255, 255, 0.7);
                color: white;
                font-size: 14px;
                font-family: 
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"';
                line-height: 18px;
                display: flex;
                align-items: center;
                margin: 0 auto;
                margin-top: 22px;
                background-image: url(../../assets/images/icon_search.svg);
                background-repeat: no-repeat;
                background-size: 15px;
                background-position: 10px 8px;
                padding-left: 30px;
                outline-style: none;
            }
        }
    }

    .MuiModal-backdrop {
        background-color: unset !important;
    }

    .panel-body {
        padding: 0;
        overflow-y: auto;
        max-height: 50vh;

        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background: rgba(255, 255, 255, .5);
        }

        &::-webkit-scrollbar-thumb {
            background-color: #fff;
            border-radius: 10px;
        }

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-corner {
            display: none;
        }

        ul {
            margin: 0;
            padding: 0;    
            
            li {
                color: white;
                font-size: 14px;
                font-family: 
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"';
                background-color: #313741;
                cursor: pointer;
                padding-left: 15px;
                height: 45px;
                line-height: 45px;
                white-space: nowrap; 
                overflow: hidden; 
                text-overflow: ellipsis;



                &:nth-child(odd) {
                    background-color: #313741;
                }
                &:nth-child(even) {
                    background-color: #3F454F; 
                }

                &:hover { 
                    background-color: #777D86;
                }
                &:focus { 
                    background-color: #7a86a0 !important;
                }
            }
        }
    }
}