
/* Simulation */
.cii-simulation-list-wrapper {

    width: 97%;
    height: calc(100% - 70px);
    margin: 0 auto;
    white-space: nowrap;
    overflow-y: hidden;

    .simulation-list {
        /* font-size: 14px; */
        width: 100%;
        height: calc(100% - 70px);
        table-layout: fixed;
        display: block;

        thead tr {
            width: 100%;
            /* height: 100%; */
            align-items: center;
            display: table;
            border-bottom: 1px dotted rgb(255, 255, 255, 0.5);
        }
        tbody tr {
            width: 100%;
            height: 50px;
            align-items: center;
            display: table;
        }

        thead {
            display: table;
            width: 100%;

            th {
                /* border: 1px #CBCBCB solid; */
                font-family: Roboto, "Helvetica Neue", sans-serif;
                font-weight: bold;
                position: sticky;

                span {
                    font-size: 14px;
                    color: rgb(255, 255, 255, 0.5);
                }
            }
            .leg-no {
                width: 6%;
                text-align: center;
            }
            .departure {
                width: 9.5%;
                text-align: left;
            }
            .padding1 {
                width: 2%;
                text-align: left;
            }
            .arrival {
                width: 9.5%;
                text-align: left;
            }
            .total-time {
                width: 7%;
                text-align: right;
            }
            .distance {
                width: 8%;
                text-align: right;
            }
            .padding1 {
                width: 2%;
            }
            .fuel {
                width: 10%;
                text-align: left;
            }
            .displacement {
                width: 7%;
                text-align: left;
            }
            .log-speed {
                width: 9%;
                text-align: right;
            }
            .foc {
                width: 6%;
                text-align: right;
            }
            .padding2 {
                width: 24%;
            }
        }

        tbody {
            display: block;
            overflow-y: auto;
            height: 90%;
            width: 100%;

            /* background-color: antiquewhite; */

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background: rgba(255, 255, 255, 0.5);
            }

            &::-webkit-scrollbar-thumb {
                background-color: #fff;
                border-radius: 10px;
            }

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-corner {
                display: none;
            }

            tr {

                width: 100%;

                &:nth-of-type(odd) {
                    background-color: rgba(255, 255, 255, 0.05);
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.15);
                }

                td {
                    padding-bottom: 0px;
                    /* border: 1px #CBCBCB solid; */
                    font-family: Roboto;
                    font-weight: 400;
                    word-wrap: 'break-word';
                    vertical-align: middle;
                    white-space: nowrap;
                    height: 55px;
                    max-height: 55px;

                    span {
                        font-size: 15px;
                        color: rgb(255, 255, 255, 1);
                    }
                }

                .leg-no {
                    width: 6%;
                    max-width: 20px;
                    text-align: center;
                }
                .departure {
                    width: 9.5%;
                    max-width: 100px;
                    text-align: left;
                    padding-left: 3px;
                }
                .padding1 {
                    width: 2%;
                    text-align: left;
                }
                .arrival {
                    width: 9.5%;
                    max-width: 100px;
                    text-align: left;
                    padding-left: 3px;
                }
                .total-time {
                    width: 7%;
                    max-width: 100px;
                    text-align: right;
                }
                .distance {
                    width: 8%;
                    max-width: 100px;
                    text-align: right;
                }
                .padding1 {
                    width: 2%;
                }
                .fuel {
                    width: 10%;
                    /* min-width: 100px; 追加 */
                    max-width: 100px;
                    text-align: left;
                    padding: 8px;
                }
                .displacement {
                    width: 7%;
                    max-width: 100px;
                    text-align: left;
                    padding-left: 5px;
                }
                .log-speed {
                    width: 9%;
                    max-width: 90px;
                    text-align: right;
                }
                .foc {
                    width: 6%;
                    max-width: 90px;
                    text-align: right;
                }
                .padding2 {
                    width: 24%;
                }               
            }
        }    
    }
}

.cii-simulation-result-titole {
    color: rgba(255, 255, 255, 0.50);
    font-size: 14px;
    font-family: "Roboto";
    font-weight: bold;
    height: 27px;

    .cii-score {
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        margin-top: -5px;
    }
}

.cii-simulation-result-param {
    color: rgba(255, 255, 255, 0.80);
    font-size: 15px;
    font-family: "Roboto";
    text-align: right;
}

.cii-simulation-result-unit {
    color: rgba(255, 255, 255, 0.50);
    font-size: 14px;
    font-family: "Roboto Condensed";
    padding-left: 8px;
}

.cii-simulation-speed-plan-header {
    /* border:1px solid #5A5A5A; */
    color: rgba(255, 255, 255, 0.50);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: bold;
    line-height: 24px;
    word-wrap: 'break-word'
}

.cii-simulation-speed-plan-data-time {
    /* border:1px solid #5A5A5A; */
    color: rgba(255, 255, 255, 0.50);
    font-size: 15px;
    font-family: 'Roboto';
    font-weight: bold;
    line-height: 24px;
    word-wrap: 'break-word';
    text-align: center;
}

.cii-simulation-pointer-area {
    cursor:pointer
}

.cii-simulation-pointer-area:hover{
    background-color: #7a86a0
}