.pgsd-dialog-titole {
    height: 60px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

/* PoolingGroup作成用 VesselList テーブル */
.pgsd-vessel-list-table-wrapper {

    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow-y: hidden;

    .pgsd-vessel-list-table {
    
        font-size: 14px;
        width: 100%;
        height: 100%;
        table-layout: fixed;
        display: block;

        thead tr {

            width: 100%;
            /* height: 100%; */
            align-items: center;
            display: table;
            border-bottom: 1px dotted rgb(255, 255, 255, 0.5);
        }
        tbody tr {
            width: 100%;
            height: 50px;
            align-items: center;
            display: table;
        }

        thead {
            display: table;
            width: 100%;

            th {
                /* border: 1px #CBCBCB solid; */
                font-family: Roboto, "Helvetica Neue", sans-serif;
                font-weight: bold;
                position: sticky;

                span {
                    font-size: 14px;
                    color: rgb(255, 255, 255, 0.5);
                }
            }

            .pgsd-vessel-list-vesselName {
                width: 248px;
                padding-left: 22px;
            }

            .pgsd-vessel-list-operator {
                width: 81px;
            }

            .pgsd-vessel-list-year-to-date {
                width: 110px
            }

            .pgsd-vessel-list-last-year {
                width: 110px;
            }

            .pgsd-vessel-list-total {
                width: 115px;
            }

            .pgsd-vessel-list-penalty-factor {
                width: 108px;
            }

            .pgsd-vessel-list-group {
                width: 145px;
                padding-right: 20px;
            }
        }

        tbody {
            display: block;
            overflow-y: auto;
            height: 90%;           
            width: 100%;

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background: rgba(255, 255, 255, 0.5);
            }

            &::-webkit-scrollbar-thumb {
                background-color: #fff;
                border-radius: 10px;
            }

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-corner {
                display: none;
            }

            tr {

                width: 100%;

                &:nth-of-type(odd) {
                    background-color: rgba(69, 72, 80, 0.25);
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.15);
                }

                td {
                    padding-bottom: 0px;
                    /* border: 1px #CBCBCB solid; */
                    font-family: Roboto;
                    font-weight: 400;
                    /* font-size: 15px; */
                    word-wrap: 'break-word';
                    vertical-align: middle;
                    white-space: nowrap;
                    height: 50px;
                    max-height: 50px;

                    span {
                        font-size: 15px;
                        color: rgb(255, 255, 255, 1);
                    }
                }

                .pgsd-vessel-list-vesselName {
                    width: 248px;
                    max-width: 248px;
                    padding-left: 22px;
                    text-align: left;
                }
    
                .pgsd-vessel-list-operator {
                    width: 81px;
                    max-width: 81px;
                    text-align: left;
                }
    
                .pgsd-vessel-list-year-to-date {
                    width: 110px;
                    max-width: 110px;
                    text-align: right;
                }
    
                .pgsd-vessel-list-last-year {
                    width: 110px;
                    max-width: 110px;
                    text-align: right;
                }
    
                .pgsd-vessel-list-total {
                    width: 115px;
                    max-width: 115px;
                    text-align: right;
                }
    
                .pgsd-vessel-list-penalty-factor {
                    width: 108px;
                    max-width: 108px;
                    text-align: right;
                    padding-right: 5px;
                }
    
                .pgsd-vessel-list-group {
                    width: 145px;
                    padding-right: 5px;
                    text-align: center;
                }
            }
        }
    }
}

