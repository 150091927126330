@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ソート用CSS */
.common-sort-list-header-left{
  display: flex;
}
.common-sort-list-header-left > div:nth-child(1) {
  margin-top: 8px;
}
.common-sort-list-header-center{
  display: flex;
  justify-content: center;
}
.common-sort-list-header-center > div:nth-child(1) {
  margin-top: 8px;
}
.common-sort-list-header-right{
  display: flex;
  justify-content: end;
}
.common-sort-list-header-right > div:nth-child(1) {
  margin-top: 8px;
}
/* 並べ替え */
.common-sort-container {
  display: inline-block;
  padding-left: 4px;

  .common-sort-icon {
      /* display: flex; */
      /* align-content: baseline; */

      height: 25px;

      .common-sort-icon-ascending {
          width: 0;
          height: 0;
          margin-left: 2px;
          margin-top: 8px;
          border-style: solid;
          border-width: 0 5px 6px 5px;
          border-color: transparent transparent rgba(255, 255, 255, 0.5) transparent;
          cursor: pointer;

          &:hover {
              border-color: transparent transparent white transparent;
          }
      }

      .common-sort-icon-descending {
          width: 0;
          height: 0;
          margin-left: 2px;
          margin-top: 5px;
          border-style: solid;
          border-width: 6px 5px 0 5px;
          border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent;
          cursor: pointer;

          &:hover {
              border-color: white transparent transparent transparent;
          }
      }

      .sort-active-ascending {
          width: 0;
          height: 0;
          margin-left: 2px;
          margin-top: 8px;
          border-style: solid;
          border-width: 0 5px 6px 5px;
          border-color: transparent transparent white transparent;
          cursor: pointer;
      }

      .sort-active-descending {
          width: 0;
          height: 0;
          margin-left: 2px;
          margin-top: 5px;
          border-style: solid;
          border-width: 6px 5px 0 5px;
          border-color: white transparent transparent transparent;
          cursor: pointer;
      }
  }
}

/* Listで文字数オーバー時の省略表示用 */
.inner-text {
  display: inline-block;
  max-width: 97%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
